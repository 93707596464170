.btn {
  transition: all var(--animation-duration);
  border-radius: 0;
  text-transform: uppercase;
}

.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
  color: inherit;
}

.btn-white {
  background: var(--color-white);
  border: 2px solid var(--color-white);
  color: #215b00;

  &:hover,
  &:focus {
    background: darken(#fff, 15%);
    border-color: darken(#fff, 15%);
  }
}

.btn-white-border {
  border: 2px solid var(--color-white);
  color: var(--color-white);
  background: transparent;

  &:hover,
  &:focus {
    color: darken(#fff, 15%);
    border-color: darken(#fff, 15%);
  }
}

.btn-orange {
  background: var(--color-orange);
  border: 2px solid var(--color-orange);
  color: var(--color-white);
}

.btn-red {
  background: var(--color-red);
  border: 2px solid var(--color-red);
  color: var(--color-white);
}

.btn-multiline {
  white-space: normal;
}

.btn-visited {
  background-color: var(--color-dark-grey);
  border-color: var(--color-dark-grey);
}
