%text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.font-normal {
  font-weight: normal;
}

.color-white {
  color: var(--color-white);
}

.color-green {
  color: var(--color-green);
}

.text-red {
  color: var(--color-red);
}

.error-message {
  color: var(--color-red);
  white-space: pre-line;
}

h2 {
  font-weight: normal;
}

.line-input {
  border-color: transparent;
  border-bottom-color: var(--color-grey);
  transition: var(--animation-duration);
  max-width: 200px;
  width: 100%;

  &:focus {
    outline: 0;
    border-bottom-color: var(--color-light-green);
  }

  &.invalid {
    border-bottom: 2px solid var(--color-red);
    background-color: var(--color-error);
  }

  &.invalid .select__control {
    background-color: var(--color-error);
  }
}

.custom-select {
  &.invalid .select__control {
    border-bottom-color: var(--color-red);
  }
}

.row-checkboxes {
  display: flex;
  align-items: center;
  justify-content: space-around;

  label.checkbox {
    margin: 10px 0;
  }
}

.checkbox.invalid {
  border-bottom: 2px solid var(--color-red);
  padding-bottom: 4px;
}

.checkbox-with-mark {
  position: relative;

  .checkbox {
    padding-left: 25px;
    left: -20px;
  }
}

.mb0 {
  margin-bottom: 0!important;
}

.mb10 {
  margin-bottom: 10px!important;
}

.mt10 {
  margin-top: 10px!important;
}
