::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--color-dark-grey);
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--color-grey);
}
