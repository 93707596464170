@font-face {
  font-family: 'GothamMedium';
  src: url('/assets/fonts/Gotham-Medium.woff2') format('woff2'), url('/assets/fonts/Gotham-Medium.woff') format('woff'), url('/assets/fonts/Gotham-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GothamBold';
  src: url('/assets/fonts/Gotham-Bold.woff2') format('woff2'), url('/assets/fonts/Gotham-Bold.woff') format('woff'), url('/assets/fonts/Gotham-Bold.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'GothamMedium';
  src: url('/assets/fonts/Gotham-BoldItalic.woff2') format('woff2'), url('/assets/fonts/Gotham-BoldItalic.woff') format('woff'), url('/assets/fonts/Gotham-BoldItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'MyriadRoman';
  src: url('/assets/fonts/Myriad-Roman.woff2') format('woff2'),
      url('/assets/fonts/Myriad-Roman.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
