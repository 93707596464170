.shipping-detail__title {
    display: flex;
    justify-content: space-between;
    gap: 12px;
    padding-bottom: 24px;
    border-bottom: 1px solid #ddd;
}

.shipping-detail__summary {
    display: flex;
    gap: 20px;
    flex-direction: column;
}

.shipping-detail-content {
    display: flex;
    flex-direction: column;
    gap: 18px;
}

.shipping-detail-content__summary {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(3, 1fr);
}

.items {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.item {
    display: grid;
    gap: 10px;
    align-items: start;
    grid-template-columns: repeat(2, 1fr);
}

.items__with-borders {
    border-right: 1px solid rgba(0, 0, 0, 0.12);
}

.shipping-page__back-button-icon {
    width: 100%;
    height: 30px;
}

.shipping-detail__heading-wrapper {
    display: flex;
    gap: 10px;
    align-items: center;
}

.shipping-page__heading {
    font-size: 24px;
}

.shipping-page__back-button {
    background-color: unset;
    border: 0;
    width: 50px;
    cursor: pointer;
}

@media (max-width: 768px) {
    .shipping-detail-content__summary {
        grid-template-columns: repeat(1, 1fr);
    }

    .item {
        grid-template-columns: repeat(3, 1fr);

        b {
            grid-column: span 2;
        }
    }

    .items__with-borders {
        border: unset;
    }

    .shipping-detail__title {
        flex-direction: column;
    }
}