.ios-auth-form {
  width: 300px;
  color: var(--color-white);
}

.separator {
  margin-top: 10px;
  width: 18%;
}

.logo-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

@media (max-width: 375px) {
  .font-adjust {
    font-size: 16px;
  }
}
