:root {
  --path-font: '../fonts';
  --path-image: '../img';

  --color-white: #fff;
  --color-black: #000;
  --color-green: #35a847;
  --color-light-green: #8bc057;
  --color-orange: #E05206;
  --color-grey: #909192;
  --color-light-grey: #dedfe0;
  --color-dark-grey: #707176;
  --color-red: #dc3545;
  --color-error: #ffe2e3;

  --font-gotham-pro: 'GothamMedium';

  --animation-duration: 0.3s;

  --category-animation-duration: 0.6s;

  --header-height: 95px;
  --footer-height: 100px;
}

html {
  min-height: 100%;
  position: relative;
}

body {
  margin-bottom: 80px;
  background: #a8d8af;
  font-family: var(--font-gotham-pro), 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 14px;
}

select.placeholder {
  color: var(--color-grey);
  text-transform: uppercase;
}

select.placeholder option {
  color: #555;
}

textarea.form-control {
  resize: vertical;
  padding-top: 20px;
}

a {
  cursor: pointer;
}

.form-group .invalid {
  border-color: var(--color-red) !important;
}

.form-control {
  border-radius: 0;
  border-color: var(--color-grey);
  height: 68px;
  font-size: 18px;
  padding-left: 40px;

  &:focus {
    border-color: var(--color-green);
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(var(--color-green), 0.075), 0 0 8px rgba(var(--color-green), 0.6);
    box-shadow: inset 0 1px 1px rgba(var(--color-green), 0.075), 0 0 8px rgba(var(--color-green), 0.6);
  }
}

.container {
  background: var(--color-white);
}

.notificator {
  .notification-container-mobile-top {
    padding-top: env(safe-area-inset-top);
  }
}

.text-divider {
  position: relative;
  margin: 18px 0 0;
  display: inline-block;
  width: 100%;

  .text {
    background-color: var(--color-white);
    color: var(--color-green);
    display: inline-block;
    padding-right: 10px;
    position: relative;
    z-index: 2;
    font-size: 20px;
  }
}

.custom-fieldset {
  border: 1px solid var(--color-grey);
  padding: 0 10px;
  margin: 10px 0;

  &.invalid {
    border: 1px solid var(--color-red);
  }

  legend {
    font-weight: 500;
    font-size: 15px;
    border: 0;
    width: auto;
    padding: 0 10px;
    margin-bottom: 0!important;
  }
}

.has-error .checkbox, .has-error .checkbox-inline, .has-error .control-label, .has-error .help-block,
.has-error .radio, .has-error .radio-inline, .has-error.checkbox label, .has-error.checkbox-inline label,
.has-error.radio label, .has-error.radio-inline label {
  color: var(--color-red);
}

.select-field {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;

  label {
    margin-right: 10px;
    flex-shrink: 0;
  }

  .required-mark {
    position: relative;
    width: 0;
  }

  .line-input {
    margin-left: 10px;
  }
}

.modal .buttons .btn-block+.btn-block {
  margin-top: 0;
}

::-webkit-input-placeholder {
  color: var(--color-grey);
  text-transform: uppercase;
}

::-moz-placeholder {
  color: var(--color-grey);
  text-transform: uppercase;
}

:-ms-input-placeholder {
  color: var(--color-grey);
  text-transform: uppercase;
}

@media (max-width: 991px) {
  .form-control {
    height: 50px;
    padding-left: 30px;
  }
}

@media (min-width: 1330px) {
  .container {
    width: 1300px;
  }
}

@media (max-width: 991px) {
  body {
    margin-bottom: 0;
  }
}
